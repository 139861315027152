import React, {
  Fragment,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {Row as AntRow, Col, Alert, message as antMessage} from 'antd';
import {Context} from '../../AppContext';
import {
  Expander,
  FormExpander,
  Text,
  Button,
  Row,
  Radio,
  RadioGroup,
  Heading,
  Divider,
  Input,
} from '../../Widgets';
import CartItemTable from '../../Components/CartItemTable';
import {ExpandMore, ExpandLess} from '@styled-icons/material';
import Calculation from '../../Components/Calculation';
import usePaymentTypeOptions from '../../hooks/use-payment-type-options';
import {ErrCheckoutForm, ErrCoupon, errorHandler} from '../../errors';
import Image from '../../Components/Image';
import {Close} from '@styled-icons/material/Close';
const appConfig = require('../../data.json');

export default function PaymentSelect(props) {
  const {goToPrevStep, goToNextStep, params, setParams, config, setConfig} =
    props;
  const app = useContext(Context);
  let {cart, profile} = app.state;
  const [type, setType] = useState(null);
  const {options, message} = usePaymentTypeOptions({type});

  if (!cart) {
    return null;
  }

  let amount = cart.calculations.amount;
  let length = cart.items.length;

  function onChange(value) {
    setType(value);
    const mapping = {
      atm: {payment_type: 'neweb', payment_subtype: value},
      credit: {payment_type: 'neweb', payment_subtype: value},
      cvs: {payment_type: 'neweb', payment_subtype: value},
      credits: {payment_type: value, payment_subtype: 'default'},
      monthly: {payment_type: value, payment_subtype: 'default'},
      offline: {payment_type: value, payment_subtype: 'default'},
      period: {payment_type: 'period', payment_subtype: 'default'},
    };
    setParams(mapping[value]);
  }

  function valid() {
    if (!type) {
      throw new ErrCheckoutForm('請選擇一種繳費方式');
    }

    const isPeriod = cart.items.find((item) => item.is_period);
    if (isPeriod) {
      throw new ErrCheckoutForm('目前不支援訂閱制商品購買。');
    }
  }

  async function submit() {
    app.actions.setLoading(true);

    try {
      valid();
      await goToNextStep();
    } catch (ex) {
      console.log(ex);
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  }

  return (
    <div>
      <Heading align="center" weight="400">
        訂單總計金額(含稅)
        <span style={{color: appConfig.colors.main}}> ${amount}</span>
      </Heading>
      <Expander
        expand={false}
        title={`總計${length}件商品`}
        titleStyle={{flex: '0 0 auto'}}
        containerStyle={{
          padding: 10,
          justifyContent: 'center',
        }}
        ExpandMoreIcon={() => (
          <ExpandMore size={40} color={appConfig.colors.main} />
        )}
        ExpandLessIcon={() => (
          <ExpandLess size={40} color={appConfig.colors.main} />
        )}>
        <CartItemTable viewOnly={true} />
      </Expander>

      <div style={{height: 20}} />

      <FormExpander title="付款方式">
        <div
          style={{
            textAlign: 'center',
            marginBottom: 20,
            padding: 20,
          }}>
          <div style={{fontWeight: '600', fontSize: 14, marginBottom: 10}}>
            {appConfig.information.name}
            由藍新金流於華南銀行開立信託專戶，專款專用安心有保障
          </div>
          <p style={{fontSize: 12}}>
            本網站採用先收款後製作，付款成功後才算正式成立訂單。請於選擇之付款方式截止日前進行繳費作業，付款方式如項目所示。
          </p>
          <p style={{fontSize: 12}}>
            若已過匯款期限，訂單將自動取消。未付款訂單保留七日後系統會自動取消，若需再次交易請重新下單，請注意您的權益。
          </p>
        </div>

        <RadioGroup
          onChange={(e) => onChange(e.target.value)}
          value={type}
          style={{width: '100%'}}>
          {options.map((option, idx) => {
            return (
              <Fragment>
                {idx !== 0 && <Divider paddingSize="lg" />}
                <Row key={idx}>
                  <Radio value={option.value}>
                    <Text>{option.label}</Text>
                  </Radio>
                  <Text color="#ccc" size="sm">
                    {option.intro}
                  </Text>
                </Row>
              </Fragment>
            );
          })}
        </RadioGroup>

        {message && <Alert message={message} type="info" />}
      </FormExpander>

      {!appConfig.isLiteVersion && <Divider paddingSize="lg" />}

      {!appConfig.isLiteVersion && (
        <div style={{padding: '10px 20px'}}>
          <Text style={{marginBottom: 10}}>折扣代碼</Text>
          <CouponInput
            code={params.coupon}
            editCode={(code) => setParams({coupon: code})}
          />
        </div>
      )}

      <Divider paddingSize="lg" />

      <AntRow style={{marginBottom: 40}}>
        <Col md={12} sm={24}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              padding: 20,
            }}>
            <Image
              src="/images/checkout-hint.png"
              width="200"
              height="auto"
              alt="checkout hint"
            />
          </div>
        </Col>
        <Col md={12} sm={24}>
          <Calculation
            calculations={cart.calculations}
            config={config.extraConfig}
            setConfig={(config) => setConfig('extra', config)}
          />

          <Row margin="20px 0">
            <Button type="default" onClick={goToPrevStep}>
              上一步
            </Button>
            <div style={{flex: 1}}></div>

            <Button
              onClick={() => {
                submit();
              }}>
              下一步
            </Button>
          </Row>
        </Col>
      </AntRow>
    </div>
  );
}

function CouponInput({code, editCode}) {
  const app = useContext(Context);
  const [coupon, setCoupon] = useState();

  const getCoupon = useCallback(async (code) => {
    try {
      let resp = await app.actions.getCoupon(code);
      setCoupon(resp);
      return resp;
    } catch (err) {}
  }, []);

  const apply = useCallback(
    async (code) => {
      app.actions.setLoading(true);

      let _coupon = await getCoupon(code);

      try {
        let _cart = await app.actions.calcOrder({coupon: code});

        // cannot use discount , because it include dicount + coupon
        // setCoupon({
        //   discount: _cart.calculations.discount + _cart.calculations.discount_tax,
        // });

        antMessage.success('此折扣代碼可使用');
        editCode(code);
      } catch (err) {
        let re = /Object with code\=\w+ does not exist\./g;
        if (err.coupon && err.coupon.length > 0 && re.test(err.coupon[0])) {
          err = new ErrCoupon('此折扣代碼不存在');
        }

        if (err.error === 'coupon_expired') {
          err = new ErrCoupon('此折扣代碼過期');
        }

        if (err.error === 'coupon_usage_limit') {
          err = new ErrCoupon('此折扣代碼已兌換完畢');
        }

        if (err.error === 'coupon_user_usage_limit') {
          err = new ErrCoupon('您的折扣代碼已使用達上限');
        }

        if (err.error === 'order_coupon_error') {
          err = new ErrCoupon(
            `訂單金額小於此折扣代碼最低門檻 ${_coupon?.threshold} 元`,
          );
        }

        if (err.error === 'coupon_user_group_error') {
          err = new ErrCoupon(`無法使用此折扣代碼`);
        }
        errorHandler(err);
        reset();
      }
      app.actions.setLoading(false);
    },
    [reset],
  );

  const reset = useCallback(async (code) => {
    app.actions.setLoading(true);
    try {
      // await app.actions.getCart();
      editCode('');
      setCoupon(null);
      await app.actions.calcOrder({coupon: ''});
    } catch (err) {}
    app.actions.setLoading(false);
  }, []);

  const selected = true;

  return (
    <CouponInputWrapper>
      <Row>
        <Input.Search
          allowClear
          onSearch={(value) => apply(value)}
          enterButton="兌換"
          style={{maxWidth: 400}}
          placeholder="請輸入折扣代碼"
          spellcheck="false"
        />
      </Row>
      <Row>
        {code && (
          <div className="ticket">
            {/* {selected ? (
            <CheckCircle color="#969696" size={20} />
          ) : (
            <Circle color="#969696" size={20} />
          )} */}
            <Close
              color="#969696"
              size="20"
              onClick={reset}
              className="ticket-close"
            />
            <div className="content">
              <Text size="sm">{code}</Text>
              {coupon && (
                <Text size="sm" weight="500" color={appConfig.colors.error}>
                  折扣金額 {coupon.discount}元
                </Text>
              )}
            </div>
          </div>
        )}
      </Row>
    </CouponInputWrapper>
  );
}

const CouponInputWrapper = styled.div`
  & .ticket {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f6f6f6;
    border: solid 0.5px ${appConfig.colors.border};
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;

    & .ticket-close {
      margin-top: 1px;

      &:hover {
        fill: ${appConfig.colors.main};
      }
    }

    & .content {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
`;
