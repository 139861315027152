import React, {
  useContext,
  Fragment,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {message} from 'antd';
import styled from 'styled-components';
import {formatValidator} from '../../Utils';
import {
  ErrorHint,
  FormExpander,
  Text,
  Button,
  Row,
  Label,
  Input,
  Hint,
  Checkbox,
  Radio,
  RadioContent,
  RadioGroup,
  Divider,
  TextArea,
} from '../../Widgets';
import {Context} from '../../AppContext';
import {isVIP, isMonthly} from '../../Utils/UserUtil';
import InvoiceForm from '../../Forms/InvoiceForm';
import AgreementSection from './AgreementSection';
import {ErrCheckoutForm, ErrInvoiceFormat} from '../../errors';
import invoiceValidator from '../../Utils/invoice-validator';
import AddressField from '../../Components/AddressField';
import {DELIVERY_TYPE, EZSHIP_STATE} from '../../dictionary';
const appConfig = require('../../data.json');
const htmls = require('../../content-html.json');

const {isNotEmpty, isEmail, isMobileNumber, isExceedStringMaxLength} =
  formatValidator;

export default function Checkout(props) {
  const {
    config,
    setConfig,
    params,
    setParams,
    updateConfig,
    goToPrevStep,
    goToNextStep,
  } = props;
  const [validate, setValidate] = useState(false);
  const [agree, setAgree] = useState({invoice: true, terms: false});
  const app = useContext(Context);
  const {profile, cart} = app.state;

  let creditsNotAllow =
    isVIP(profile) &&
    !isMonthly(profile) &&
    profile.credits < cart.calculations.amount;

  let monthlyQuotaRunOut =
    isMonthly(profile) && profile.monthly_quota < cart.calculations.amount;

  function valid(config) {
    if (!agree.invoice || !agree.terms) {
      throw new ErrCheckoutForm('請閱讀且同意注意事項');
    }

    const {invoiceConfig, userConfig, deliveryConfig} = config;

    // user config
    let {name, phone, city, district, address, email} = userConfig;
    if (
      !isNotEmpty(name) ||
      !isNotEmpty(city) ||
      !isNotEmpty(district) ||
      !isNotEmpty(address) ||
      !isNotEmpty(email) ||
      !isNotEmpty(phone)
    ) {
      throw new ErrCheckoutForm('請填入會員資料必填欄位');
    }
    if (!isEmail(email)) {
      throw new ErrCheckoutForm('會員資料電子信箱格式錯誤');
    }
    if (!isMobileNumber(phone)) {
      throw new ErrCheckoutForm('會員資料手機格式錯誤');
    }

    if (isExceedStringMaxLength(name)) {
      throw new ErrCheckoutForm('會員資料名稱長度過長');
    }

    if (isExceedStringMaxLength(address)) {
      throw new ErrCheckoutForm('會員資料通訊地址長度過長');
    }

    //delivery config
    let {
      delivery_type,
      is_delivery_private = '',
      receiver_name = '',
      receiver_phone = '',
      receiver_city = null,
      receiver_district = null,
      receiver_address = '',
      sender_name = '',
      sender_phone = '',
      sender_city = null,
      sender_district = null,
      sender_address = '',
    } = deliveryConfig;

    if (
      ['hct', 'mailing', 'tcat', 'kerry_tj', 'special_car'].includes(
        delivery_type,
      )
    ) {
      if (
        !isNotEmpty(receiver_name) ||
        !isNotEmpty(receiver_phone) ||
        !isNotEmpty(receiver_city) ||
        !isNotEmpty(receiver_district) ||
        !isNotEmpty(receiver_address)
      ) {
        throw new ErrCheckoutForm('請填入宅配運送/收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm('收件人手機格式錯誤');
      }
    } else if (delivery_type === 'ezship') {
      if (!isNotEmpty(receiver_name) || !isNotEmpty(receiver_phone)) {
        throw new ErrCheckoutForm('請填入收件人必填欄位');
      }
      if (!isMobileNumber(receiver_phone)) {
        throw new ErrCheckoutForm('收件人手機格式錯誤');
      }
    } else {
      // self pick
    }

    if (is_delivery_private) {
      /* 保密代記 */
      if (
        !isNotEmpty(sender_name) ||
        !isNotEmpty(sender_phone) ||
        !isNotEmpty(sender_city) ||
        !isNotEmpty(sender_district) ||
        !isNotEmpty(sender_address)
      ) {
        throw new ErrCheckoutForm('請填入保密代寄/寄件人必填欄位');
      }
      if (!isMobileNumber(sender_phone)) {
        throw new ErrCheckoutForm('保密代寄/寄件人手機格式錯誤');
      }

      if (
        sender_name === receiver_name ||
        sender_phone === receiver_phone ||
        sender_address === receiver_address
      ) {
        throw new ErrCheckoutForm('保密代寄/寄件人資料不可和收件人資料相同');
      }

      if (isExceedStringMaxLength(sender_name)) {
        throw new ErrCheckoutForm('保密代寄/寄件人名長度過長');
      }

      if (isExceedStringMaxLength(sender_address)) {
        throw new ErrCheckoutForm('保密代寄/寄件地址長度過長');
      }
    }

    if (isExceedStringMaxLength(receiver_name)) {
      throw new ErrCheckoutForm('收件人名長度過長');
    }

    if (isExceedStringMaxLength(receiver_address)) {
      throw new ErrCheckoutForm('收件地址長度過長');
    }

    //invoice
    invoiceValidator(invoiceConfig);
  }

  function submit() {
    app.actions.setLoading(true);
    try {
      valid(config);
      if (
        window.confirm(
          '請確認會員資料及收件資訊是否填寫，為維護您的權益請務必填寫正確再下單。',
        )
      ) {
        let {userConfig} = config;
        app.actions.editProfile({
          address: userConfig.address,
          city: userConfig.city,
          district: userConfig.district,
          name: userConfig.name,
          phone: userConfig.phone,
          zip_code: userConfig.zip,
        });
        updateConfig();
        goToNextStep();
      }
    } catch (err) {
      console.warn(err);

      if (err instanceof ErrInvoiceFormat || err instanceof ErrCheckoutForm) {
        message.error(err.message);
      }
    }
    app.actions.setLoading(false);
  }

  return (
    <div>
      <FormExpander title="會員資料">
        <UserForm
          config={config}
          setConfig={(config) => setConfig('user', config)}
          validate={validate}
        />
      </FormExpander>

      <FormExpander title="收件資訊">
        <DeliveryForm
          config={config}
          setConfig={(config) => setConfig('delivery', config)}
          validate={validate}
        />
      </FormExpander>

      <FormExpander title="發票資料">
        <InvoiceForm
          config={config.invoiceConfig}
          setConfig={(config) => setConfig('invoice', config)}
          validate={validate}
          agree={agree}
          setAgree={(obj) => setAgree((prev) => ({...prev, ...obj}))}
        />
      </FormExpander>

      <FormExpander title="訂單注意事項">
        <Row margin="20px 0 40px 0">
          <Label>訂單備註</Label>
          <TextArea
            style={{flex: 1}}
            value={params.note}
            placeholder="請輸入製作備註/加工備註/訂單備註。"
            onChange={(e) => setParams({note: e.target.value})}
          />
        </Row>
        <AgreementSection
          setAgree={(obj) => setAgree((prev) => ({...prev, ...obj}))}
        />
      </FormExpander>

      <Row
        margin="20px 0"
        style={{display: 'flex', alignItems: 'center', margin: ''}}>
        <Button
          type="default"
          style={{
            color: appConfig.colors.borderSecond,
            borderColor: appConfig.colors.borderSecond,
          }}
          onClick={goToPrevStep}>
          上一步
        </Button>
        <div style={{flex: 1}}></div>
        {creditsNotAllow ? (
          <Text size="sm" style={{marginRight: 10}}>
            您的點數餘額不足！請先儲值點數
          </Text>
        ) : profile.is_frozen ? (
          <Text size="sm" style={{marginRight: 10}}>
            您的帳號已被凍結，請聯絡客服
          </Text>
        ) : monthlyQuotaRunOut ? (
          <Text size="sm" style={{marginRight: 10}}>
            您的月結餘額不足，月結額度{profile.monthly_quota}元，已使用
            {profile.monthly_used_quota}元，請聯絡客服
          </Text>
        ) : null}
        <Button
          disabled={creditsNotAllow || monthlyQuotaRunOut}
          onClick={() => {
            setValidate(true);
            submit();
          }}>
          下一步
        </Button>
      </Row>
    </div>
  );
}

function UserForm({config: _config, setConfig, validate}) {
  const config = _config.userConfig;
  return (
    <>
      <Row>
        <Label>會員名稱</Label>
        <Input
          placeholder="會員名稱"
          value={config.name}
          onChange={(e) => setConfig({...config, name: e.target.value})}
        />
        {validate && !isNotEmpty(config.name) && (
          <ErrorHint>會員名稱不能為空</ErrorHint>
        )}
        <Hint>＊會員名稱建議填寫本名，避免造成無法取件之情形。</Hint>
      </Row>

      <Row>
        <Label>手機號碼</Label>
        <Input
          placeholder="手機號碼"
          value={config.phone}
          onChange={(e) => setConfig({...config, phone: e.target.value})}
        />
        {validate &&
          (!isNotEmpty(config.phone) || !isMobileNumber(config.phone)) && (
            <ErrorHint>請確認手機號碼格式</ErrorHint>
          )}
      </Row>

      <Row>
        <Label>市話號碼</Label>
        <Input
          placeholder="市話"
          value={config.tel}
          onChange={(e) => setConfig({...config, tel: e.target.value})}
        />
        <Input
          type="short"
          placeholder="分機"
          value={config.tel_ext}
          onChange={(e) => setConfig({...config, tel_ext: e.target.value})}
        />
      </Row>

      <Row>
        <Label>電子信箱</Label>
        <Input
          type="long"
          placeholder="電子信箱"
          value={config.email}
          onChange={(e) => setConfig({...config, email: e.target.value})}
        />
        {validate && (!isNotEmpty(config.email) || !isEmail(config.email)) && (
          <ErrorHint>電子信箱不能為空</ErrorHint>
        )}
      </Row>

      <Row>
        <Label>通訊地址</Label>
        <AddressField
          zip_code={config.zip}
          city={config.city}
          district={config.district}
          address={config.address}
          onChange={(obj) =>
            setConfig({
              ...config,
              ...obj,
              ...(obj.zip_code !== undefined && {zip: obj.zip_code}),
            })
          }
        />
        {/* <Input
          type="short"
          placeholder="郵遞區號"
          value={config.zip}
          onChange={(e) => setConfig({...config, zip: e.target.value})}
        />
        <Input
          type="long"
          placeholder="通訊地址"
          value={config.address}
          onChange={(e) => setConfig({...config, address: e.target.value})}
        /> */}
        {validate && !isNotEmpty(config.address) && (
          <ErrorHint>通訊地址不能為空</ErrorHint>
        )}
        {validate &&
          (!isNotEmpty(config.city) || !isNotEmpty(config.district)) && (
            <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
          )}
      </Row>
    </>
  );
}

function DeliveryForm({config: _config, setConfig, validate}) {
  const config = _config.deliveryConfig;
  const userConfig = _config.userConfig;
  const app = useContext(Context);
  const [ezshipMapUrl] = useState(app.actions.getCvsUrl());

  return (
    <div style={{margin: '10px 0'}}>
      <RadioGroup
        onChange={(e) => {
          const value = e.target.value;

          if (value === 'ezship') {
            window.open(ezshipMapUrl, '_self');
            return;
          }

          setConfig({
            ...config,
            delivery_type: value,
          });
        }}
        value={config.delivery_type}>
        {(() => {
          let index = appConfig.supportLogistics.findIndex(
            (key) =>
              key === 'hct' ||
              key === 'tcat' ||
              key === 'kerry_tj' ||
              key === 'mailing' ||
              key === 'special_car',
          );

          if (index === -1) {
            return null;
          }

          return (
            <div key={appConfig.supportLogistics[index]}>
              <Row margin="10px 0 0 0">
                <Radio value={appConfig.supportLogistics[index]}>
                  <Text>宅配</Text>
                </Radio>
              </Row>
              {config.delivery_type === appConfig.supportLogistics[index] && (
                <DeliveryDetailForm
                  deliveryConfig={config}
                  userConfig={userConfig}
                  setConfig={setConfig}
                  validate={validate}
                />
              )}
            </div>
          );
        })()}

        {appConfig.supportLogistics.includes('self_pick') && (
          <div>
            <Row margin="10px 0 0 0">
              <Radio value="self_pick">
                <Text>自行取貨</Text>
              </Radio>
              <Hint>
                ＊收到訂單完成自取通知後，請在到店自取前，通知客服取貨時間預先幫您備貨。
              </Hint>
            </Row>

            {config.delivery_type === 'self_pick' ? (
              <RadioContent>
                <div
                  dangerouslySetInnerHTML={{__html: htmls.checkout_self_pick}}
                />
              </RadioContent>
            ) : null}
          </div>
        )}
        {appConfig.supportLogistics.includes('ezship') && (
          <div>
            <Row margin="10px 0 0 0">
              <Radio
                value="ezship"
                onClick={() =>
                  message.info('即將導向台灣便利配選擇超商介面...')
                }>
                <Text>超商純取貨</Text>
              </Radio>
              <Hint>
                ＊台灣便利配服務，僅限全家、萊爾富、OK便利商店，到超商只有單純取貨，沒有繳費服務。收件人請填寫證件本名(須與貨件上的取件人相同，避免造成無法取件之情形)。
              </Hint>
            </Row>

            {config.delivery_type === 'ezship' ? (
              <RadioContent>
                <Title>選取門市資料</Title>
                <Row>
                  <Label>通路</Label>
                  <Text size="sm" style={{margin: '0 10px'}}>
                    {EZSHIP_STATE[config.st_state]}
                  </Text>
                </Row>
                <Row>
                  <Label>門市名稱</Label>
                  <Text size="sm" style={{margin: '0 10px'}}>
                    {config.rstore_name}
                  </Text>
                </Row>
                <Row>
                  <Label>門市地址</Label>
                  <Text size="sm" style={{margin: '0 10px'}}>
                    {config.rstore_addr}
                  </Text>
                </Row>
                <Title>
                  收件人資料
                  <Button
                    style={{marginLeft: 10}}
                    onClick={() => {
                      setConfig({
                        ...config,
                        receiver_name: userConfig.name,
                        receiver_phone: userConfig.phone,
                      });
                    }}>
                    同會員資料
                  </Button>
                </Title>
                <Row>
                  <Label>收件人名</Label>
                  <Input
                    placeholder="收件人名"
                    value={config.receiver_name}
                    onChange={(e) =>
                      setConfig({...config, receiver_name: e.target.value})
                    }
                  />
                  {validate && !isNotEmpty(config.receiver_name) && (
                    <ErrorHint>收件人名不能為空</ErrorHint>
                  )}
                  <Hint>＊會員名稱建議填寫本名，避免造成無法取件之情形。</Hint>
                </Row>

                <Row>
                  <Label>手機號碼</Label>
                  <Input
                    placeholder="手機號碼"
                    value={config.receiver_phone}
                    onChange={(e) =>
                      setConfig({...config, receiver_phone: e.target.value})
                    }
                  />
                  {validate &&
                    (!isNotEmpty(config.receiver_phone) ||
                      !isMobileNumber(config.receiver_phone)) && (
                      <ErrorHint>請確認手機號碼格式</ErrorHint>
                    )}
                </Row>
              </RadioContent>
            ) : null}
          </div>
        )}
      </RadioGroup>

      <Divider />
    </div>
  );
}

function DeliveryDetailForm({
  deliveryConfig: config,
  userConfig,
  setConfig,
  validate,
}) {
  return (
    <RadioContent>
      <Checkbox
        checked={config.is_delivery_private}
        onChange={(e) => {
          setConfig({
            ...config,
            is_delivery_private: e.target.checked,
          });
        }}>
        <Text inline>保密代寄</Text>
      </Checkbox>
      <Hint>
        ＊保密代寄：使用會員名義寄送貨品，直接幫您寄送到指定地址，不會出現本公司的名字。
      </Hint>
      {config.is_delivery_private && (
        <>
          <Title>
            寄件人資料
            <Button
              style={{marginLeft: 10}}
              onClick={() => {
                setConfig({
                  ...config,
                  sender_name: userConfig.name,
                  sender_phone: userConfig.phone,
                  sender_tel: userConfig.tel,
                  sender_zip: userConfig.zip,
                  sender_city: userConfig.city,
                  sender_district: userConfig.district,
                  sender_address: userConfig.address,
                });
              }}>
              同會員資料
            </Button>
          </Title>
          <Row>
            <Label>寄件人名</Label>
            <Input
              placeholder="寄件人名"
              value={config.sender_name}
              onChange={(e) =>
                setConfig({
                  ...config,
                  sender_name: e.target.value,
                })
              }
            />
            {validate && !isNotEmpty(config.sender_name) && (
              <ErrorHint>寄件人名不能為空</ErrorHint>
            )}
          </Row>

          <Row>
            <Label>手機號碼</Label>
            <Input
              placeholder="手機號碼"
              value={config.sender_phone}
              onChange={(e) =>
                setConfig({
                  ...config,
                  sender_phone: e.target.value,
                })
              }
            />
            {validate &&
              (!isNotEmpty(config.sender_phone) ||
                !isMobileNumber(config.sender_phone)) && (
                <ErrorHint>請確認手機號碼格式</ErrorHint>
              )}
          </Row>

          <Row>
            <Label>市話號碼</Label>
            <Input
              placeholder="市話"
              value={config.sender_tel}
              onChange={(e) =>
                setConfig({...config, sender_tel: e.target.value})
              }
            />
            <Input
              placeholder="分機"
              type="short"
              value={config.sender_tel_ext}
              onChange={(e) =>
                setConfig({
                  ...config,
                  sender_tel_ext: e.target.value,
                })
              }
            />
          </Row>

          <Row>
            <Label>寄件地址</Label>
            <AddressField
              zip_code={config.sender_zip}
              city={config.sender_city}
              district={config.sender_district}
              address={config.sender_address}
              onChange={(obj) => {
                setConfig({
                  ...config,
                  ...(obj.zip_code !== undefined && {
                    sender_zip: obj.zip_code,
                  }),
                  ...(obj.city !== undefined && {
                    sender_city: obj.city,
                  }),
                  ...(obj.district !== undefined && {
                    sender_district: obj.district,
                  }),
                  ...(obj.address !== undefined && {
                    sender_address: obj.address,
                  }),
                });
              }}
            />
            {validate && !isNotEmpty(config.sender_address) && (
              <ErrorHint>寄件地址不能為空</ErrorHint>
            )}
            {validate &&
              (!isNotEmpty(config.sender_city) ||
                !isNotEmpty(config.sender_district)) && (
                <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
              )}
          </Row>
        </>
      )}
      <Title>
        收件人資料
        <Button
          style={{marginLeft: 10}}
          onClick={() => {
            setConfig({
              ...config,
              receiver_name: userConfig.name,
              receiver_phone: userConfig.phone,
              receiver_tel: userConfig.tel,
              zip: userConfig.zip,
              receiver_city: userConfig.city,
              receiver_district: userConfig.district,
              receiver_address: userConfig.address,
            });
          }}>
          同會員資料
        </Button>
      </Title>
      <Row>
        <Label>收件人名</Label>
        <Input
          placeholder="收件人名"
          value={config.receiver_name}
          onChange={(e) =>
            setConfig({...config, receiver_name: e.target.value})
          }
        />
        {validate && !isNotEmpty(config.receiver_name) && (
          <ErrorHint>收件人名不能為空</ErrorHint>
        )}
        <Hint>＊會員名稱建議填寫本名，避免造成無法取件之情形。</Hint>
      </Row>

      <Row>
        <Label>手機號碼</Label>
        <Input
          placeholder="手機號碼"
          value={config.receiver_phone}
          onChange={(e) =>
            setConfig({...config, receiver_phone: e.target.value})
          }
        />
        {validate &&
          (!isNotEmpty(config.receiver_phone) ||
            !isMobileNumber(config.receiver_phone)) && (
            <ErrorHint>請確認手機號碼格式</ErrorHint>
          )}
      </Row>

      <Row>
        <Label>市話號碼</Label>
        <Input
          placeholder="市話"
          value={config.receiver_tel}
          onChange={(e) => setConfig({...config, receiver_tel: e.target.value})}
        />
        <Input
          placeholder="分機"
          type="short"
          value={config.receiver_tel_ext}
          onChange={(e) =>
            setConfig({
              ...config,
              receiver_tel_ext: e.target.value,
            })
          }
        />
      </Row>

      <Row>
        <Label>備用電話</Label>
        <Input
          placeholder="備用電話"
          value={config.receiver_phone_spare}
          onChange={(e) =>
            setConfig({
              ...config,
              receiver_phone_spare: e.target.value,
            })
          }
        />
      </Row>

      <Row>
        <Label>收件地址</Label>
        <AddressField
          zip_code={config.zip}
          city={config.receiver_city}
          district={config.receiver_district}
          address={config.receiver_address}
          onChange={(obj) =>
            setConfig({
              ...config,
              ...(obj.zip_code !== undefined && {zip: obj.zip_code}),
              ...(obj.city !== undefined && {receiver_city: obj.city}),
              ...(obj.district !== undefined && {
                receiver_district: obj.district,
              }),
              ...(obj.address !== undefined && {
                receiver_address: obj.address,
              }),
            })
          }
        />
        {validate && !isNotEmpty(config.receiver_address) && (
          <ErrorHint>通訊地址不能為空</ErrorHint>
        )}
        {validate &&
          (!isNotEmpty(config.receiver_city) ||
            !isNotEmpty(config.receiver_district)) && (
            <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
          )}
      </Row>

      <Row>
        <Label>收件備註</Label>
        <Input
          placeholder="收件備註"
          value={config.delivery_note}
          onChange={(e) =>
            setConfig({...config, delivery_note: e.target.value})
          }
        />
        <Hint>
          ＊若您下單的品項包裝後超過基本宅配材積
          (單筆訂單限1才5kg內)，或您的地址為貨運聯運地區，客服會再聯繫您做補收款動作。
        </Hint>
      </Row>
    </RadioContent>
  );
}

const Title = styled.p`
  color: #3e3a39;
  font-size: 16px;
  margin: 20px 0px;
`;
